var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Rabbit MQ Server Address")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      disabled: _vm.messageType === "azure"
                    },
                    model: {
                      value: _vm.rabbit.address,
                      callback: function($$v) {
                        _vm.$set(_vm.rabbit, "address", $$v)
                      },
                      expression: "rabbit.address"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Rabbit MQ Server Port")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      disabled: _vm.messageType === "azure"
                    },
                    model: {
                      value: _vm.rabbit.port,
                      callback: function($$v) {
                        _vm.$set(_vm.rabbit, "port", $$v)
                      },
                      expression: "rabbit.port"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Rabbit MQ Server ID")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      disabled: _vm.messageType === "azure"
                    },
                    model: {
                      value: _vm.rabbit.id,
                      callback: function($$v) {
                        _vm.$set(_vm.rabbit, "id", $$v)
                      },
                      expression: "rabbit.id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Rabbit MQ Server Password")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      type: "password",
                      disabled: _vm.messageType === "azure"
                    },
                    model: {
                      value: _vm.rabbit.pw,
                      callback: function($$v) {
                        _vm.$set(_vm.rabbit, "pw", $$v)
                      },
                      expression: "rabbit.pw"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v(" Database ")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.group,
                        callback: function($$v) {
                          _vm.group = $$v
                        },
                        expression: "group"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "MySQL", value: "mysql" }
                      }),
                      _c("v-radio", {
                        attrs: { label: "PostgreSQL", value: "pgsql" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Database Server Address")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.db.address,
                      callback: function($$v) {
                        _vm.$set(_vm.db, "address", $$v)
                      },
                      expression: "db.address"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Database Server Port")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.db.port,
                      callback: function($$v) {
                        _vm.$set(_vm.db, "port", $$v)
                      },
                      expression: "db.port"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Database Server ID")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.db.id,
                      callback: function($$v) {
                        _vm.$set(_vm.db, "id", $$v)
                      },
                      expression: "db.id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Database Server Password")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      type: "password"
                    },
                    model: {
                      value: _vm.db.pw,
                      callback: function($$v) {
                        _vm.$set(_vm.db, "pw", $$v)
                      },
                      expression: "db.pw"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Database Name")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.db.database,
                      callback: function($$v) {
                        _vm.$set(_vm.db, "database", $$v)
                      },
                      expression: "db.database"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("Backend Server Address")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.backend,
                      callback: function($$v) {
                        _vm.backend = $$v
                      },
                      expression: "backend"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "4" } }, [
                _c("label", [_vm._v("SoluM API Server Address")])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      clearable: "",
                      outlined: "",
                      "hide-details": "",
                      dense: ""
                    },
                    model: {
                      value: _vm.apiURL,
                      callback: function($$v) {
                        _vm.apiURL = $$v
                      },
                      expression: "apiURL"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "btn btn-info",
              attrs: { text: "" },
              on: { click: _vm.saveProperty }
            },
            [_vm._v(" Save ")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                overflow: "hidden",
                "text-align": "center"
              }
            },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: 50, width: 7, color: "#ddd" }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }