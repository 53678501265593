<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <!-- <label> Message Server </label>
        </v-col>
        <v-col cols="4">
          <v-radio-group v-model="messageType" row>
            <v-radio disabled label="Azure IoT Hub" value="azure"></v-radio>
            <v-radio disabled label="Rabbit MQ" value="rabbitmq"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4"> -->
          <label>Rabbit MQ Server Address</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="rabbit.address" :disabled="messageType === 'azure'"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Rabbit MQ Server Port</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="rabbit.port" :disabled="messageType === 'azure'"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Rabbit MQ Server ID</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="rabbit.id" :disabled="messageType === 'azure'"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Rabbit MQ Server Password</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="rabbit.pw" type="password" :disabled="messageType === 'azure'"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label> Database </label>
        </v-col>
        <v-col cols="4">
          <v-radio-group v-model="group" row>
            <v-radio label="MySQL" value="mysql"></v-radio>
            <v-radio label="PostgreSQL" value="pgsql"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Database Server Address</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="db.address"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Database Server Port</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="db.port"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Database Server ID</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="db.id"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Database Server Password</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="db.pw" type="password"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Database Name</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="db.database"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>Backend Server Address</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="backend"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <label>SoluM API Server Address</label>
        </v-col>
        <v-col cols="6">
          <v-text-field clearable outlined hide-details dense v-model="apiURL"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn text class="btn btn-info" @click="saveProperty">
        Save
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="isLoading" persistent>
      <div style="width: 100%; overflow: hidden; text-align: center">
        <v-progress-circular
          indeterminate
          :size="50"
          :width="7"
          color="#ddd"
        ></v-progress-circular>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import dicamoApi from '@/lcd/plugins/dicamoApi'
import EventBus from '@/plugins/eventBus'
export default {
  name: 'LCDSetting',
  data () {
    return {
      backend: '',
      apiURL: '',
      rabbit: {
        address: '',
        port: '',
        id: '',
        pw: ''
      },
      db: {
        address: '',
        port: '',
        id: '',
        pw: '',
        database: ''
      },
      mysqlDB: false,
      postgresqlDB: false,
      group: '',
      messageType: '',
      text: '',
      isLoading: false
    }
  },
  mounted () {
    this.getServerData()
  },
  methods: {
    getServerData () {
      this.$utils
        .callAxios(
          dicamoApi.requests.getServerSetting.method,
          dicamoApi.requests.getServerSetting.url
        )
        .then(res => {
          if (res.data.result) {
            const getData = JSON.parse(res.data.data)
            this.rabbit.address = getData.serverHost
            this.rabbit.port = getData.serverPort
            this.rabbit.id = getData.serverID
            this.rabbit.pw = getData.serverPW
            this.group = String(getData.dbType).toLowerCase() === 'pgsql' ? 'pgsql' : 'mysql'

            this.db.address = getData.dbHost
            this.db.port = getData.dbPort
            this.db.id = getData.dbID
            this.db.pw = getData.dbPW
            this.db.database = getData.database
            this.backend = getData.backend
            this.apiURL = getData.apiURL
          }
        })
    },
    saveProperty () {
      this.isLoading = true
      const data = new FormData()

      data.append('rabbit_host', this.rabbit.address)
      data.append('rabbit_port', this.rabbit.port)
      data.append('rabbit_id', this.rabbit.id)
      data.append('rabbit_pass', this.rabbit.pw)

      data.append('db_type', this.group)
      data.append('db_host', this.db.address)
      data.append('db_port', this.db.port)
      data.append('db_id', this.db.id)
      data.append('db_pass', this.db.pw)
      data.append('db_database', this.db.database)
      data.append('backend', this.backend)
      data.append('api_url', this.apiURL)

      this.$utils
        .callAxiosWithBody(
          dicamoApi.requests.postServerSetting.method,
          dicamoApi.requests.postServerSetting.url,
          data
        )
        .then(res => {
          console.log(res.headers['content-length'])
          if (res.headers['content-length'] < 100) {
            setTimeout(() => {
              EventBus.$emit('messageAlert', this.$t('SUCCESS'))
              this.isLoading = false
            }, 60000)
          } else {
            EventBus.$emit('messageAlert', this.$t('FAILED'))
            this.isLoading = false
          }
        })
        .catch(error => {
          console.debug(`Post error Server setting. error:, ${error}`)
          this.isLoading = false
        })
    },
    initialize () {
      this.rabbit = {
        address: '',
        port: '',
        id: '',
        pw: ''
      }
      this.db = {
        address: '',
        port: '',
        id: '',
        pw: '',
        database: ''
      }
      this.backend = ''
    }
  }
}
</script>
